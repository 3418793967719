<template>
    <div>
        <img :src="require('@/assets/img/helpdetail1.png')" />
    </div>
</template>

<script>
import { nextTick, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'

export default {
    setup() {
        const router = useRouter()

        nextTick(() => {
            document.removeEventListener('touchmove', window.touchmoveListener, { passive: false })
        })
        onUnmounted(() => {
            document.addEventListener('touchmove', window.touchmoveListener, { passive: false })
        })

        return { router }
    }
}
</script>

<style lang="scss" scoped>
img {
    width: 100%;
}
</style>
